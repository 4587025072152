// documentation: https://partner.cashpresso.com/#/api#posCalculator

const startCashpresso = (apiKey, freeDays, referrerCode, targetSelector, productAmount) => {
  // eslint-disable-next-line no-undef
  $(document).ready(() => {
    // eslint-disable-next-line no-undef
    CreditCalculator.init(
      {
        targetSelector,
        language: 'de',
        ...productAmount && { amount: productAmount },
        // given freeDays is verified to BK contract with Cashpresso - maximum allowed amount.
        // does not verify if no promo is currently running.
        // sometimes2*30 free days added automatically (coming from Cashpresso)
        interestFreeDays: freeDays ?? 0,
        partnerApiKey: apiKey,
        hideButton: false,
        referrerCode,
        format: 'wide',
        mode: 'live',
      },
    );
  });
};

export default startCashpresso;
