var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BikeDeliveryContent',{attrs:{"headline":_vm.$t('Your delivery date')}},[(_vm.bikeDelivery.notificationSent)?[_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.getSanitizedHtml(
          'The current delivery date for your bike is',
          _vm.bikeDelivery.estimatedDeliveryWeek
        )
      )}}),_vm._v(" "),_c('p',[_vm._v("\n      "+_vm._s(_vm.$t("The bike was assembled by the manufacturer and shipped to us."))+"\n    ")])]:[_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.getSanitizedHtml(
          'We expect your bike in',
          _vm.bikeDelivery.estimatedDeliveryWeek
        )
      )}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }