























import { defineComponent } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import BKButton from '~/components/BK/BKButton.vue';
import { EnumButtonVariant } from './models/Button.model';

export default defineComponent({
  name: 'FinancingButton',
  components: {
    BKButton,
  },
  props: {
    variant: { type: String, default: '' },
    text: { type: String, default: '' },
    center: { type: Boolean, default: false },
  },
  setup() {
    const {
      toggleFinancingModal,
    } = useUiState();

    return {
      toggleFinancingModal,
      EnumButtonVariant,
    };
  },
});
