



































































































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';
import { ProductStockStatus } from '@vue-storefront/magento-api';
import { discount } from '~/helpers/priceHelper';
import { isBreakpointLg } from '~/cms/helpers/breakpoints';
import { clockIcon, fireIcon, saleIcon } from '~/cms/helpers/customIcons';
import { BadgeEnum } from '~/composables';
import { arrowRightIcon } from '~/cms/helpers/customIcons';
import { ProductVariant } from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import BKPrice from './BKPrice.vue';
import { getMinimumRate } from '../Financing/financing';

export enum SupportedHeadlineTag {
  H2 = 'h2',
  H3 = 'h3',
}

interface ITag {
  icon: string;
  text: string;
  class: string;
}

// if the size filter is active, check if the product is available in the selected size
// this also handles multi select as multiple sizes could be active in the filters - at least one of the sizes needs to have stock
const isVariantAvailable = (variants: ProductVariant[] | null, activeSizeFilterValues: string[], fallbackStockStatus: string) => {
  const variantFound = variants?.find((variant) => activeSizeFilterValues.includes(variant.attributes?.[0]?.value_index?.toString()));
  return variantFound?.product?.quantity > 0 ?? fallbackStockStatus === ProductStockStatus.InStock;
};

export default defineComponent({
  name: 'BKProductCard',
  components: {
    SfIcon,
    BKPrice,
  },
  props: {
    image: {
      type: [Array, Object, String],
      default: '',
    },
    manufacturer: {
      type: String,
      default: '',
    },
    badge: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    groupset: {
      type: String,
      default: '',
    },
    engine: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: null,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    stockStatus: {
      type: String,
      default: ProductStockStatus.InStock,
    },
    showAsNew: {
      type: Boolean,
      default: false,
    },
    activeSizeFilterValues: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    variants: {
      type: Array as PropType<ProductVariant[] | null>,
      default: () => null,
    },
    headlineTag: {
      type: String as PropType<SupportedHeadlineTag>,
      default: SupportedHeadlineTag.H2,
    },
    finalPrice: {
      type: Number,
      default: 0,
    },
    isBikeCategory: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    let badgeTag: ITag = { icon: '', text: '', class: '' };
    const {
      specialPrice, stockStatus, badge, link, groupset, engine, activeSizeFilterValues, variants, manufacturer, finalPrice,
    } = props;

    const path = link as string || '';
    const attributeTitle = groupset ? 'Groupset' : (engine ? 'Engine' : '');
    const attribute = groupset ?? engine;

    // if the size filter is active, check if the product is available in the selected size
    // this also handles multi select as multiple sizes could be active in the filters - at least one of the sizes needs to have stock
    const available = activeSizeFilterValues && activeSizeFilterValues.length > 0
      ? isVariantAvailable(variants, activeSizeFilterValues, stockStatus)
      : stockStatus === ProductStockStatus.InStock;

    switch (badge) {
      case BadgeEnum.SALE:
        badgeTag = { icon: saleIcon, text: 'Sale', class: 'bk-product-card__badge--sale' };
        break;
      case BadgeEnum.NEW:
        badgeTag = { icon: fireIcon, text: 'New', class: 'bk-product-card__badge--new' };
        break;
      case BadgeEnum.AVAILABLE_SOON:
        badgeTag = { icon: clockIcon, text: 'Available soon', class: 'bk-product-card__badge--available-soon' };
        break;
      default:
        badgeTag = { icon: '', text: '', class: '' };
        break;
    }

    const isMobile: boolean = false;
    const hasManufacturer = computed(() => manufacturer && manufacturer.length > 0);
    const minimumRate = computed(() => getMinimumRate(finalPrice || 0));

    return {
      available,
      badgeTag,
      attributeTitle,
      attribute,
      discount: specialPrice,
      path,
      isMobile,
      hasManufacturer,
      minimumRate,
      arrowRightIcon,
    };
  },
  mounted() {
    window.addEventListener('resize', this.calculateBreakpoints);
    this.calculateBreakpoints();
  },
  unmounted() {
    window.removeEventListener('resize', this.calculateBreakpoints);
  },
  methods: {
    calculateBreakpoints() {
      this.isMobile = isBreakpointLg(window);
    },
    calculateDiscount() {
      return discount(this.regularPrice, this.specialPrice);
    },
  },
});
