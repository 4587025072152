import type { ImageModifiers } from '@nuxt/image';
import { computed, Ref, useContext } from '@nuxtjs/composition-api';
import { getProductImage } from '~/composables';
import {
  getBadge,
  getCustomAttributes,
  getName,
  getPrice,
  getProductPath,
  getProductThumbnailImage,
} from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';
import { UseContextReturn } from '~/types/core';

export interface ProductVariant {
  attributes?: {
    code: string;
    label: string;
    value_index: number;
  }[];
  product?: {
    uid: string;
    quantity: number;
    stock_status: string;
  }
}

export interface ProductCommonCardProps {
  productType: string;
  manufacturer: string;
  title: string;
  link: string;
  style: Record<string, string | number> | string;

  image: string;
  imageTag: string;
  nuxtImgConfig: { [key in keyof ImageModifiers]?: ImageModifiers[key] };

  regularPrice: string;
  specialPrice: string;
  maximumPrice: string;
  finalPrice: number;
  variants?: ProductVariant[];
}

export type ProductWithCommonProductCardProps = Product & { commonProps: ProductCommonCardProps };

export const useGetCommonProps = (context: UseContextReturn, product: Product, index?: number) => {
  const imageProps = {
    image: getProductImage(context, getProductThumbnailImage(product)),
    imageTag: 'nuxt-img',
    nuxtImgConfig: { fit: 'cover' },
  };

  const price = getPrice(product);

  const priceProps = {
    regularPrice: context.$fc(price.regular),
    specialPrice: price.special && context.$fc(getPrice(product).special),
    maximumPrice: price.maximum && context.$fc(getPrice(product).maximum),
    finalPrice: price.special || price.regular || 0,
  };

  const link = getProductPath(product);

  const customAttributes = getCustomAttributes(product);

  const variants = product.variants?.map((variant) => {
    const attributes = variant.attributes.map((attribute) => ({
      code: attribute.code,
      label: attribute.label,
      value_index: attribute.value_index,
    }));
    return {
      attributes,
      product: {
        uid: variant.product.uid,
        quantity: variant.product.quantity,
        stock_status: variant.product.stock_status,
      },
    };
  });

  return {
    // eslint-disable-next-line no-underscore-dangle
    productType: product?.__typename,
    stockStatus: product?.stock_status,
    manufacturer: customAttributes?.manufacturer_name || customAttributes?.manufacturer_goods,
    groupset: customAttributes?.groupset,
    engine: customAttributes?.manufacturer_engine_name,
    badge: getBadge(product),
    title: getName(product),
    link,
    style: { '--index': index }, // used for transition animation
    variants: variants ?? [],
    ...imageProps,
    ...priceProps,
  };
};

export const useProductsWithCommonProductCardProps = (products: Ref<Product[]>) => {
  const context = useContext();

  const productsWithCommonProductCardProps = computed<ProductWithCommonProductCardProps[]>(() => products.value.map((product, index) => {
    const commonProps = useGetCommonProps(context, product, index);
    return {
      ...product,
      commonProps,
    };
  }));
  return { productsWithCommonProductCardProps };
};
