import Vue from 'vue';

import BikeDelivery from '~/cms/BikeDelivery/BikeDelivery.vue';
import Calendly from '~/cms/Calendly.vue';
import CallToAction from '~/cms/CallToAction.vue';
import Cards from '~/cms/Cards/Cards.vue';
import CategoriesSlider from '~/cms/CategoriesSlider.vue';
import Claim from '~/cms/Claim.vue';
import CmsHeader from '~/cms/CmsHeader.vue';
import CmsImage from '~/cms/CmsImage.vue';
import CmsTable from '~/cms/CmsTable.vue';
import ContactForm from '~/cms/ContactForm/ContactForm.vue';
import DienstradForm from '~/cms/DienstradForm/DienstradForm.vue';
import FAQs from '~/cms/FAQs.vue';
import HeadlineText from '~/cms/HeadlineText.vue';
import HeroHeader from '~/cms/HeroHeader.vue';
import IFrame from '~/cms/IFrame.vue';
import JobList from '~/cms/JobList.vue';
import KeyFacts from '~/cms/KeyFacts/KeyFacts.vue';
import List from '~/cms/List.vue';
import ProductsGrid from '~/cms/ProductsGrid.vue';
import SingleButton from '~/cms/SingleButton.vue';
import TeaserSlider from '~/cms/TeaserSlider.vue';
import Teasers from '~/cms/Teasers/Teasers.vue';
import TextImage from '~/cms/TextImage.vue';
import BikeFinder from '~/cms/BikeFinder.vue';
import FinancingButton from '~/cms/FinancingButton.vue';

import AppHeader from '~/components/AppHeader.vue';
import AppFooter from '~/components/Footer/AppFooter.vue';
import Newsletter from '~/components/Newsletter.vue';
import TopBar from '~/components/TopBar/TopBar.vue';
import FinancingModal from '~/components/Financing/FinancingModal.vue';

// Layout
Vue.component('Navigation', AppHeader);
Vue.component('TopBar', TopBar);
Vue.component('AppFooter', AppFooter);
Vue.component('Newsletter', Newsletter);
Vue.component('FinancingModal', FinancingModal);

// Components
Vue.component('BikeDelivery', BikeDelivery);
Vue.component('Claim', Claim);
Vue.component('TextImage', TextImage);
Vue.component('HeadlineText', HeadlineText);
Vue.component('HeroHeader', HeroHeader);
Vue.component('Teasers', Teasers);
Vue.component('CmsImage', CmsImage);
Vue.component('CmsHeader', CmsHeader);
Vue.component('CallToAction', CallToAction);
Vue.component('List', List);
Vue.component('Cards', Cards);
Vue.component('FAQs', FAQs);
Vue.component('JobList', JobList);
Vue.component('CmsTable', CmsTable);
Vue.component('CategoriesSlider', CategoriesSlider);
Vue.component('ProductsGrid', ProductsGrid);
Vue.component('SingleButton', SingleButton);
Vue.component('KeyFacts', KeyFacts);
Vue.component('TeaserSlider', TeaserSlider);
Vue.component('ContactForm', ContactForm);
Vue.component('DienstradForm', DienstradForm);
Vue.component('IFrame', IFrame);
Vue.component('Calendly', Calendly);
Vue.component('BikeFinder', BikeFinder);
Vue.component('FinancingButton', FinancingButton);
