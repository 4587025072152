







































import { defineComponent, useContext } from '@nuxtjs/composition-api';
import { SfBar, SfModal } from '@storefront-ui/vue';
import { useUiState } from '~/composables/useUiState';
import { Routes } from '~/helpers/routes';
import startCashpresso from './cashpresso';

export default defineComponent({
  name: 'FinancingModal',
  components: {
    SfModal,
    SfBar,
  },
  setup() {
    const { isFinancingModalOpen, financingMetadata } = useUiState();
    const { $config } = useContext();
    const { cashpressoApiKey, cashpressoInterestFreeDays, cashpressoReferrerCode } = $config;

    return {
      isFinancingModalOpen,
      financingMetadata,
      cashpressoApiKey,
      cashpressoInterestFreeDays,
      cashpressoReferrerCode,
      Routes,
    };
  },
  mounted() {
    startCashpresso(this.cashpressoApiKey, this.cashpressoInterestFreeDays, this.cashpressoReferrerCode, '#financing', this.financingMetadata?.price as number || 0);
  },
});
